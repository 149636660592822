import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { node } from 'prop-types'

import { config, strings} from './config'

const IntlContext = React.createContext()
const IntlConsumer = IntlContext.Consumer

function IntlProvider({children}) {

  const userLanguage = localStorage.getItem('lang') || window.navigator.userLanguage || window.navigator.language
  const [selectedLanguage, setLanguage] = useState(config.languages[userLanguage.substring(0, 2)] || config.languages[config.default])
  const [searchParams, setSearchParams] = useSearchParams()

  function _setLanguage(lang){
    localStorage.setItem('lang', lang.shortcode)
    if (searchParams.has('lang')) {
      searchParams.delete('lang')
      setSearchParams(searchParams)
    }    

    setLanguage(lang)
  }

  function getTranslation(data){
    let { group, id, defaultValue, variables } = data
    if (!group) group = "default"
    let string = strings[selectedLanguage.shortcode][group] && strings[selectedLanguage.shortcode][group][id] ? strings[selectedLanguage.shortcode][group][id] : defaultValue

    if (variables){
      Object.keys(variables).forEach((variable) => {
        let regex = new RegExp(`{([${variable}}]+)}`, 'g')
        if (string){
          string = string.replace(regex, variables[variable])
        }
      })
    }

    return string || `No translation defined: ${id}`
  }

  function getTranslatedValue(data){
    if (!data) return null
    return data[selectedLanguage.shortcode]
  }

  return (
    <IntlContext.Provider
      value={{
        selectedLanguage,
        userLanguage,
        languages: config.languages,
        strings,
        setLanguage: _setLanguage,
        getTranslation,
        getTranslatedValue
      }}
    >
      {children}
    </IntlContext.Provider>
  )
}

IntlProvider.propTypes = {
  children: node.isRequired,
}

export { IntlContext, IntlProvider, IntlConsumer}
